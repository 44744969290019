import i18n from "i18next";
import {initReactI18next} from "react-i18next";


const resources = {
    en: {
        translation: {
            "NavbarHome": "Home",
            "NavbarNews": "News",
            "NavbarMaciej": "Maciej",
            "NavbarBolid": "Formula 4",
            "NavbarSezon": "Season 2024",
            "NavbarMedia": "Media",
            "NavbarKontakt": "Contact",
            "NavbarLang": "Language",

            "NavbarLangMobile": "Zmień Język",

            "bio": "I was born on April 28, 2008, in Tarnów, Poland. I come from a family with long motorsport traditions. My grandfather Janusz competed in car rallies, and my dad Adam is a multiple Polish champion.",
            "button_01": "Show more",

            "partners": "Sponsors",
            "under_title": "Articles",

            "button_02": "Show more",
            "button_03": "Show less",
            "content_01": "Date and place of birth: April 28, 2008., Tarnów (PL)",
            "content_01a": "Family: Mother Joanna, Father Adam, Sister Natalia",
            "content_01b": "Athlete pattern: Charles Leclerc, Robert Kubica",
            "content_01c": "Hobbies: boxing, bicycle, ninja warrior",
            "content_01d": "Team: MP – Formula 4",
            "content_01e": "ASN: Member of the National Team",
            "tittle_01": "IMPORTANT ACHIEVEMENTS:",
            "content_011": "Rookie Champion – F4 Formula Winter Series",
            "content_011a": "II vice Champion - F4 Formula Winter Series",

            "content_02": "Ferrari Driver Academy Camp – Fiorano",
            "content_02a": "FIA European Championship",
            "content_02b": "Champions of the Future",
            "content_02c": "WSK Super Master Series",

            "content_03": "4th FIA World Championship – (Italy)",
            "content_03a": "Champion of Champions of the Future (Winter Series)",
            "content_03b": "vice Champion of WSK Euro Series",

            "content_04": "Champion of FIA Karting Academy Trophy",

            "content_05": "Champion WSK Open Cup",
            "content_05a": "3rd place in Trofeo Andrea Margutti",
            "content_05b": "II vice Champion of the Rok Cup Superfinal",
            "content_05c": "II vice Champion of WSK Super Master Series",

            "content_06": "Polish Karting Champion",
            "content_06a": "Champion of Rok Cup Poland",
            "content_06b": "2nd WSK Super Master Series – (Napoli)",
            "content_06c": "vice Champion of Rok Cup Italia 2019 – (Adria)",
            "content_06d": "Winner of Rok Cup Italy Nord 2019 – (Adria)",

            "content_07": "vice Polish Champion",
            "content_07a": "II vice Polish Champion of Rok Cup Poland",

            "content_08": "Champion of Rotax Max Challenge",
            "content_08a": "Polish Youth Champion",
            "content_08b": "Polish Karting Champion",
            "text_01": "I was born on April 28, 2008, in Tarnów, Poland. I come from a family with long motorsport traditions. My grandfather Janusz competed in car rallies, and my dad Adam is a multiple Polish Champion.",
            "text_02": "Together, they also organized the unprecedented Volkswagen Golf Cup racing series. Both my sister Natalia and uncle Marcin also competed successfully in the past. Raised on racing tracks across Europe, I knew what I wanted to do in life from a very young age.",
            "text_03": "My career gained momentum in 2017, two years after I began racing, when I won the first of three Polish Karting Championship titles as a nine-year-old.",
            "text_04": "Since then, I have triumphed numerous times in national series like the Rok Cup Poland and the Rotax Max Challenge Poland, and internationally, racing as a protege of the ORLEN Academy and a member of the Polish National Team.",
            "text_05": "My biggest successes include winning the prestigious WSK Open Cup in the 2020 season and claiming the FIA Karting Academy Trophy title the following year, becoming the first Pole in history to do so. After receiving this famous trophy during the FIA Gala in Paris, I was also named “Driver of the Year” by the “Polski Karting” magazine.",
            "text_07": "I also finished second overall in the WSK Super Master Series and, as only the second Pole after Robert Kubica in history, stood on the podium of the famous 31st Andrea Margutti Trophy. I also achieved the fastest lap time during that event.",
            "text_08": "In 2022, I began a successful collaboration with the distinguished British team, Ricky Flynn Motorsport, winning the FIA Champions of the Future Winter Series, the WSK Euro Series races in Sarno, Italy, securing the vice-championship in this series, and placing fourth among 109 competitors at the FIA World Championship at the same venue.",
            "text_09": "During the 2023 season I was one of the frontrunners of the FIA European Karting Championship, Champions of the Future and the World Series Karting.",
            "text_10": "Simultaneously, at the invitation of the Ferrari Driver Academy, I participated in a special training camp for young talents, testing the Formula 4 car of the Prema Racing team on Ferrari's test track in Fiorano, Italy.",
            "text_11": "Further successful tests opened the door for my racing debut in Formula 4 with the renowned team, MP Motorsport, with which the current Formula 1 Champion, Max Verstappen, began his career. This Dutch team has been setting the pace and winning the most famous racing series, from Formula 4 to Formula 2, for three decades.",
            "text_12": "In the 2024 season, I will compete in the international Spanish Formula 4 Championship, with races on many European tracks known from Formula 1, such as Circuit de Barcelona-Catalunya and Circuit Paul Ricard.",
            "text_13": "As part of the preparations for the main F4 season, I took part in the international Formula Winter Series Championship, taking third overall with three podiums from 11 races and winning the Rookie Championship with seven podiums, including six wins.",
            "text_14": "Since the FWS 2024 attracted over 40 of the best young drivers from around the world, I consider this result a great success and a perfect way to start my Formula 4 career.",
            "text_15": "Off the track, my life is filled with passions such as boxing, cycling, and ninja warrior, which not only provide relaxation but also build my character and determination. Besides my dad and grandfather, my sports idols are Charles Leclerc and Robert Kubica.",
            "button_04": "Maciej on Wikipedia",

            "title_bolid_01": "Constructor ",
            "title_bolid_02": "Predecessor ",
            "title_bolid_03": "Technical specifications",
            "1_1": "Chassis",
            "2_1": "Carbon-fiber monocoque, fiberglass body",
            "1_2": "Suspension (front)",
            "2_2": "Push-rod with twin non-adjustable shock absorbers, adjustable anti-roll bar and third element",
            "1_3": "Suspension (rear)",
            "2_3": "Push-rod with twin non-adjustable shock absorbers, adjustable anti-roll bar and third element",
            "1_4": "Height",
            "2_4": "950 mm (37 in)",
            "1_5": "Axle track",
            "2_5": "Axle track 1,510 mm (59 in) (front) 1,460 mm (57 in) (rear)",
            "1_6": "Wheelbase",
            "2_6": "2,750 mm (108 in)",
            "1_7": "Engine",
            "2_7": "Abarth FT-J/Autotecnica 1,368 cc (83 cu in) DOHC inline-4 turbocharged, longitudinally mounted in a mid-engined, rear-wheel drive layout",
            "1_8": "Transmission",
            "2_8": "SADEV 6-speed semi-automatic sequential gearbox",
            "1_9": "Power",
            "2_9": "180–185 hp (134–138 kW)",
            "1_10": "Weight",
            "2_10": "Weight 570 kg (1,257 lb) including driver",
            "1_11": "Fuel",
            "2_11": "Various unleaded control fuel",
            "1_12": "Lubricants",
            "2_12": "Various",
            "1_13": "Brakes",
            "2_13": "4-piston calipers Ventilated aluminum brake discs",
            "1_14": "Tyres",
            "2_14": "Hankook",

            "contactDetailsPressOfficer": "Contact details for the press officer.",
            "buttonTextSocialMedia": "Find out more",
            "socialMediaTextInstagram": "Take a look behind the scenes of my racing career and everyday life. Here you'll find photos from competitions, training sessions, and my free time.",
            "socialMediaTextFacebook": "Join my community where I share the latest news, achievements, and live events.",
            "socialMediaTextYouTube": "Watch my racing videos, vlogs, interviews, and behind-the-scenes footage. Welcome to my official channel!",
            "socialMediaTextX": "Follow my short, real-time updates from the track and beyond. Here you'll find my thoughts, reactions, and comments.",
            "socialMediaTextTikTok": "Check out my racing challenges, short videos from training sessions, and daily life in dynamic clips.",

            "Title": "Season 2024",
            "Title2": "Contact",

            "articleWywiad": "interview",

            // ADD HERE ARTICLE TITLES (ENG)

            "art_01_title": "Maciej Gładysz takes his first F4 title",
            "art_02_title": "Maciej Gładysz back at the front in Formula 4",
            "art_03_title": "Maciej Gładysz at the front again in Formula 4",
            "art_04_title": "Maciej Gładysz ready for F4 debut in Spain",
            "art_05_title": "Perfect F4 debut for Maciej Gładysz",
            "art_06_title": "Maciej Gładysz to Race for Poland in FIA Motorsport Games",
            "art_07_title": "Maciej Gładysz starts the Spanish F4 season",
            "art_08_title": "Maciej Gładysz on the podium in Spanish F4 debut",
            "art_09_title": "Maciej Gładysz takes points in Portimao",
            "art_10_title": "Maciej Gładysz Ready to Race on Another F1 Track",
            "art_11_title": "Maciej Gładysz Takes First Formula 4 Victory",
            "art_12_title": "Maciej Gładysz aiming high in F4 in Aragon",
            "art_13_title": "Double F4 Victory for Maciej Gładysz in Aragon",
            "art_14_title": "Maciej Gładysz back in action in Valencia",
            "art_15_title": "Maciej Gładysz on F4 podium again in Valencia",
            "art_16_title": "Maciej Gładysz ready for Jerez",
            "art_17_title": "Maciej Gładysz Twice on the Podium in Jerez",

        }
    },
    pl: {
        translation: {
            "NavbarHome": "Home",
            "NavbarNews": "News",
            "NavbarMaciej": "Maciej",
            "NavbarBolid": "Formuła 4",
            "NavbarSezon": "Sezon 2024",
            "NavbarMedia": "Media",
            "NavbarKontakt": "Kontakt",
            "NavbarLang": "Język",

            "NavbarLangMobile": "Change Language",

            "bio": "Urodziłem się 28 kwietnia 2008 roku w Tarnowie. Pochodzę z rodziny z długimi, motorsportowymi tradycjami. Mój dziadek Janusz rywalizował w rajdach samochodowych, a mój tata Adam to wielokrotny mistrz Polski w wyścigach.",
            "button_01": "Zobacz więcej",

            "partners": "Sponsorzy",
            "under_title": "Artykuły",
            "button_02": "Pokaż więcej",
            "button_03": "Pokaż mniej",

            "content_01": "Data i miejsce urodzenia: 28.04.2008r., Tarnów (PL)",
            "content_01a": "Rodzina: Mama Joanna, Tata Adam, Siostra Natalia",
            "content_01b": "Wzór sportowca: Charles Leclerc, Robert Kubica",
            "content_01c": "Hobby: boks, rower, ninja warrior",
            "content_01d": "Zespół: MP Motorsport – Formula 4",
            "content_01e": "Polski Związek Motorowy: Członek Kadry Narodowej",
            "tittle_01": "WAŻNE OSIĄGNIĘCIA:",

            "content_011": "Mistrz Rookie – F4 Formula Winter Series",
            "content_011a": "II vice mistrz w klasyfikacji generalnej – F4 Formula Winter Series",

            "content_02": "Ferrari Driver Academy Camp – Fiorano (Włochy)",
            "content_02a": "Mistrzostwa Europy FIA",
            "content_02b": "Champions of the Future",
            "content_02c": "World Series Karting",

            "content_03": "4. miejsce Mistrzostwa Świata FIA – Włochy",
            "content_03a": "Mistrz Champions of the Future (Winter Series)",
            "content_03b": "vice Mistrz WSK Euro Series",

            "content_04": "Mistrz FIA Karting Academy Trophy",

            "content_05": "Mistrz WSK Open Cup",
            "content_05a": "III miejsce Trofeo Andrea Margutti",
            "content_05b": "II vice Mistrz Rok Cup Superfinal",
            "content_05c": "II vice Mistrz WSK Super Master Series",

            "content_06": "Kartingowy Mistrz Polski Młodzików",
            "content_06a": "Mistrz Rok Cup Poland",
            "content_06b": "vice Mistrz Rok Cup Italia final – (Adria) Włochy",
            "content_06c": "II miejsce WSK Super Master Series – (Sarno) Włochy",
            "content_06d": "I miejsce Rok Cup Italy Nord – (Adria) Włochy",

            "content_07": "Kartingowy vice Mistrz Polski Młodzików",
            "content_07a": "II vice Mistrz Rok Cup Poland",

            "content_08": "Kartingowy Mistrz Polski",
            "content_08a": "Kartingowy Mistrz Polski Młodzików",
            "content_08b": "Mistrz Rotax Max Challenge Poland",

            "text_01": "Urodziłem się 28 kwietnia 2008 roku w Tarnowie. Pochodzę z rodziny z długimi, motorsportowymi tradycjami. Mój dziadek Janusz rywalizował w rajdach samochodowych, a mój tata Adam to wielokrotny mistrz Polski w wyścigach.",
            "text_02": "Razem przez lata organizowali także bezprecedensowy cykl wyścigów Volkswagen Golf Cup. Na torze z powodzeniem rywalizowali również moja siostra Natalia i wujek Marcin. Praktycznie wychowując się na torach wyścigowych całej Europy od najmłodszych lat wiedziałem, co chcę robić w życiu.",
            "text_03": "Moja kariera nabrała tempa, gdy w 2017 roku - dwa lata po rozpoczęciu startów - jako dziewięciolatek wywalczyłem pierwszy z trzech tytułów kartingowego mistrza Polski.",
            "text_04": "Od tego czasu triumfowałem wielokrotnie w zawodach ogólnopolskich - jak Rok Cup Poland oraz Rotax Max Challenge Poland - i międzynarodowych, ścigając się jako podopieczny Akademii ORLEN i członek polskiej Kadry Narodowej.",
            "text_05": "Moje największe sukcesy to m.in. zwycięstwo w prestiżowych zawodach WSK Open Cup w sezonie 2020 oraz sięgnięcie rok później po tytuł FIA Karting Academy Trophy, jako pierwszy Polak w historii. Po odebraniu tego słynnego trofeum podczas Gali FIA w Paryżu zostałem także wybrany „Kierowcą Roku” magazynu „Polski Karting”.",
            "text_07": "Sięgnąłem również po wicemistrzostwo WSK Super Master Series, a także – jako dopiero drugi po Robercie Kubicy Polak w historii – stanąłem na podium słynnych zawodów o 31. Trofeum Andrei Margutti. Uzyskałem wówczas również najlepszy czas okrążenia.",
            "text_08": "W 2022 roku rozpocząłem pełną sukcesów współpracę z utytułowanym, brytyjskim zespołem Ricky Flynn Motorsport. W jego barwach wywalczyłem mistrzostwo cyklu FIA Champions of the Future Winter Series, wygrałem zawody WSK Euro Series we włoskim Sarno, zdobyłem wicemistrzostwo tej serii, a także, na tym samym obiekcie, zająłem czwarte miejsce w stawce 109 zawodników mistrzostw świata FIA.",
            "text_09": "W sezonie 2023 rywalizowałem w czołówce klasy OK w Kartingowych Mistrzostwach Europy FIA, Champions of the Future oraz cyklu World Series Karting.",
            "text_10": "Jednocześnie, na zaproszenie Ferrari Driver Academy, wziąłem udział czterodniowym w specjalnym obozie treningowym dla młodych talentów, testując bolid Formuły 4 ekipy Prema Racing na torze Ferrari we włoskim Fiorano.",
            "text_11": "Kolejne udane testy otworzyły przede mną drzwi do wyścigowego debiutu w Formule 4 w barwach renomowanego zespołu MP Motosport, w którym swoją karierę rozpoczynał aktualny mistrz Formuły 1, Max Verstappen. Ta holenderska ekipa narzuca tempo i wygrywa najsłynniejsze serie wyścigowe, od Formuły 4 po Formułę 2, od trzech dekad.",
            "text_12": "W sezonie 2024 wystartuję w pełnym cyklu międzynarodowych mistrzostw Hiszpanii Formuły 4, których wyścigi odbędą się na wielu europejskich torach znanych z Formuły 1, jak Circuit de Barcelona-Catalunya czy Circuit Paul Ricard.",
            "text_13": "Elementem przygotowań do głównej części sezonu F4 były starty w międzynarodowym cyklu Formula Winter Series, w którym rywalizowałem z ponad 40 zawodnikami z całego świata, 7 z 11 wyścigów kończąc na podium w mocno obsadzonej klasyfikacji debiutantów – w tym 6 wygrywając – a także trzy razy stając na podium w klasyfikacji łącznej.",
            "text_14": "Całe zmagania zakończyłem z tytułem mistrza w klasyfikacji debiutantów oraz drugiego wicemistrza w klasyfikacji łącznej, co w tak licznej i wymagającej stawce uważam za ogromny sukces i wymarzony debiut w Formule 4.",
            "text_15": "Poza torem moje życie wypełniają pasje takie jak boks, jazda na rowerze i ninja warrior, które nie tylko są formą relaksu, ale także budują mój charakter i determinację. Moi sportowi idole, poza moim tatą i dziadkiem, to Charles Leclerc i Robert Kubica.",
            "button_04": "Maciej na Wikipedii",

            "title_bolid_01": "Konstruktor ",
            "title_bolid_02": "Poprzednik ",
            "title_bolid_03": "Specyfikacja techniczna",
            "1_1": "Podwozie",
            "2_1": "Monokok z włókna węglowego, nadwozie z włókna szklanego",
            "1_2": "Zawieszenie (przednie)",
            "2_2": "Popychacz z dwoma nieregulowanymi amortyzatorami, regulowanym stabilizatorem i trzecim elementem",
            "1_3": "Zawieszenie (tylnie)",
            "2_3": "Typu push-rod z dwoma nieregulowanymi amortyzatorami, regulowanym stabilizatorem i trzecim elementem",
            "1_4": "Wysokość",
            "2_4": "950 mm",
            "1_5": "Rozstaw kół",
            "2_5": "1,510 mm (przód), 1,460 mm (tył)",
            "1_6": "Rozstaw osi",
            "2_6": "2,750 mm",
            "1_7": "Silnik",
            "2_7": "Abarth FT-J/Autotecnica 1,368 ccm DOHC, czterocylindrowy, rzędowy, turbodoładowany, przenoszący moc tylko na tylną oś, montowany wzdłużnie, centralnie",
            "1_8": "Skrzynia biegów",
            "2_8": "6-biegowa, półautomatyczna, sekwencyjna, SADEV ",
            "1_9": "Moc",
            "2_9": "180–185 KM (134–138 kW)",
            "1_10": "Waga",
            "2_10": "570 kg z kierowcą",
            "1_11": "Paliwo",
            "2_11": "Bezołowiowe, uzależnione od serii",
            "1_12": "Lubrykanty",
            "2_12": "Różne, uzależnione od serii",
            "1_13": "Hamulce",
            "2_13": "Aluminiowe, wentylowane tarcze z 4-tłoczkowymi zaciskami",
            "1_14": "Opony",
            "2_14": "Hankook",

            "contactDetailsPressOfficer": "Dane kontaktowe do oficera prasowego.",
            "buttonTextSocialMedia": "Sprawdź tutaj",
            "socialMediaTextInstagram": "Zajrzyj za kulisy mojej kariery wyścigowej i życia codziennego. Znajdziesz tu zdjęcia z zawodów, treningów oraz mojego wolnego czasu.",
            "socialMediaTextFacebook": "Dołącz do mojej społeczności, gdzie dzielę się najnowszymi wiadomościami, osiągnięciami oraz wydarzeniami na żywo.",
            "socialMediaTextYouTube": "Oglądaj moje filmy z wyścigów, vlogi, wywiady i materiały zza kulis. Zapraszam na mój oficjalny kanał!",
            "socialMediaTextX": "Śledź moje krótkie, bieżące aktualizacje z życia torowego i poza nim. Tutaj znajdziesz moje myśli, reakcje i komentarze.",
            "socialMediaTextTikTok": "Zobacz moje wyścigowe wyzwania, krótkie filmiki z treningów i codziennego życia w formie dynamicznych klipów.",


            "Title": "Sezon 2024",
            "Title2": "Kontakt",

            "articleWywiad": "wywiad",
            // ADD HERE ARTICLE TITLES (PL)

            "art_01_title": "Maciej Gładysz sięga po tytuł mistrzowski w Formule 4",
            "art_02_title": "Maciej Gładysz w czołówce Formuły 4 w Aragonii",
            "art_03_title": "Maciej Gładysz ponownie w czołówce Formuły 4",
            "art_04_title": "Maciej Gładysz rozpoczyna sezon F4 w Jerez",
            "art_05_title": "Wymarzony debiut Gładysza w Formule 4",
            "art_06_title": "Maciej Gładysz Reprezentantem Polski na 3. Igrzyskach Sportów Motorowych FIA Motorsport Games",
            "art_07_title": "Maciej Gładysz rozpoczyna sezon w hiszpańskiej Formule 4",
            "art_08_title": "Maciej Gładysz na podium w debiucie w hiszpańskiej F4",
            "art_09_title": "Widowiskowa pogoń i punkty Gładysza w Portimao",
            "art_10_title": "Maciej Gładysz gotowy do walki na kolejnym torze F1",
            "art_11_title": "Maciej Gładysz wygrywa w Formule 4",
            "art_12_title": "Maciej Gładysz po kolejne trofea w F4 w Aragonii",
            "art_13_title": "Podwójne zwycięstwo Macieja Gładysza w F4 w Aragonii",
            "art_14_title": "Maciej Gładysz wraca do walki w Walencji",
            "art_15_title": "Maciej Gładysz na podium w Walencji",
            "art_16_title": "Maciej Gładysz gotowy do walki w Jerez",
            "art_17_title": "Maciej Gładysz dwukrotnie na podium w Jerez",
        }
    }
};


i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "pl",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
