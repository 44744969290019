import React from "react";
import '../sass/homeNews.css';

import CustomH2 from "./technicalComponents/CustomH2";
import CustomArticle from "./technicalComponents/CustomArticle";

import CustomButton from "./technicalComponents/CustomButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import article15 from "../img/articles/article15.webp";
import article16 from "../img/articles/article16.webp";
import article17 from "../img/articles/article17.webp";


const HomeNews = () => {


    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/news');
    }


    return(
        <section className="newsSection">
            <CustomH2 text="News"/>
            <div className="newsSection-grid">
                <CustomArticle
                    date="2024-10-06"
                    title={t("art_17_title")}
                    image={article17}
                    isLarge={true}
                    linkEN="https://mailchi.mp/577a58674629/maciej-gadysz-twice-on-the-podium-in-jerez?e=9eec90125b"
                    linkPL="https://mailchi.mp/22c3932aae54/maciej-gadysz-dwukrotnie-na-podium-w-jerez?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-10-02"
                    title={t("art_16_title")}
                    image={article16}
                    isLarge={false}
                    linkEN="https://mailchi.mp/94a5e8263ef3/maciej-gadysz-ready-for-jerez?e=9eec90125b"
                    linkPL="https://mailchi.mp/e987f6df45a5/maciej-gadysz-gotowy-do-walki-w-jerez?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-09-16"
                    title={t("art_15_title")}
                    image={article15}
                    isLarge={false}
                    linkEN="https://mailchi.mp/ffe2b2871837/maciej-gadysz-on-f4-podium-again-in-valencia?e=9eec90125b"
                    linkPL="https://mailchi.mp/8c28d642a2e7/maciej-gadysz-na-podium-w-walencji?e=9eec90125b"
                />
            </div>
            <div className="buttonContainer">
                <CustomButton label={t("button_01")} onClick={handleClick}/>
            </div>
        </section>
    );
};

export default HomeNews;